@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import '@design';
.custom-card-blog {
  &:hover p {
    color: $main-green !important;
  }
  border: 0.5px solid $main-grey80 !important;
}
